<template>
    <div class="GlobalTable">
      <template v-if="seachDataList.length > 0">
          <GlobalTable  ref="companyDiffFundTable" v-loading="loading"
            :columns="tableColumns3" :data="seachDataList" :currentPage="page.pageNum" :total="page.total"
            @handleCurrentChange="handleCurrentChange">
            <el-table-column label="账单流水号" slot="uniqNo" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.serType == '18'" style="color: red;">{{ row.uniqNo || "--" }}</span>
                <span v-else>{{ row.uniqNo || "--" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="门店商家" slot="companyName" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.companyName || "--" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="账单类型" slot="opt" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.opt == '01' ? '上账' : row.opt == '02' ? '下账' : '挂账' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="账单金额" slot="amount" align="center">
              <template slot-scope="{ row }">
                <span :style="row.opt == '01' ? 'color:green' : 'color:red'" v-if="row.opt != '03'">{{ row.amount >= 0 &&
                  row.opt ==
                  '01' ? `+${row.amount}` : `-${row.amount}` }}</span>
                <span :style="row.serType == '15' || row.serType == '23' ? 'color:green' : 'color:red'" v-else>{{ row.amount
                  &&
                  (row.serType == '15' || row.serType == '23') ? `+${row.amount}` : `-${row.amount}` }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作人" slot="merchantName" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.adminName}}-{{ row.adminAcc }}</span>
              </template>
            </el-table-column>
            <el-table-column label="当前账户余额" slot="nowBalance" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.nowBalance || "--" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" width="130rpx" slot="remark" align="center">
              <template slot-scope="{ row }">
                <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
                  <div class="Remarks">
                    {{ row.remark }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
          </GlobalTable>
      </template>
      <template v-else>
        <div v-loading="searchTabLoading" 
          style="width: 100%; text-align: center; padding: 80px 0px; font-size: 24px; color: rgb(102, 102, 102); background: white; font-weight: bold;">
          暂无数据~
        </div>
      </template>
      <!-- 查看图片 -->
      <el-image-viewer
        :zIndex="99999"
        v-if="showViewer"
        :on-close="
          () => {
            imgList = [];
            showViewer = false;
          }
        "
        :url-list="imgList"
      />
    </div>
  </template>
  
  <script>
  import ElImageViewer from "element-ui/packages/image/src/image-viewer";
  import _api from "@/utils/request";
  export default {
    name: "Table",
    components: { ElImageViewer },
    props: {
      accountType: {
        require: true,
        type: String
      },
    },
    data() {
      return {
        searchTabLoading:true,
        loading: false,
        seachDataList: [],
        page: {
          pageNum: 1,
          pageSize: 10
        },
        showViewer: false,
        imgList: [],
        pageNum: 1,
        tableColumns3: [//门店差异基金账单
          { slotName: "uniqNo" },
          { slotName: "companyName" },
          { slotName: "opt" },//账单类型
          { label: "账单时间", prop: "createTime" },
          { slotName: "amount" },
          { slotName: "merchantName" },
          { slotName: "nowBalance" },//当前差异基金余额
          { slotName: "remark" }
        ],
      };
    },
    created() {
      this.handleCurrentChange();
    },
    computed: {
      SeachParams() {
        return this.$store.state.tagsView.SeachParams
      },
    },
    watch: {
      SeachParams(newVal) {
        console.log(newVal)
        this.page.pageNum = 1;
        this.handleCurrentChange()
      },
      accountType(newVal) {
        this.searchTabLoading = true;
        this.seachDataList = [];
        console.log(newVal, 'accountType');
        this.SeachParams.opt = ""
        this.SeachParams.serType = ""
        this.handleCurrentChange()
      }
    },
    methods: {
      // 表格切换页面
      handleCurrentChange(val) {
        if (val) {
          this.pageNum = val
          this.page.pageNum = val;
        }
        const baseRequest = {
          accountType: this.accountType,
          merchantId: this.SeachParams.merchantId,
          companyId: this.SeachParams.companyId,
          storeId: this.SeachParams.storeId,
          uniqNo: this.SeachParams.uniqNo,
          orderNo: this.SeachParams.orderNo,
          opt: this.SeachParams.opt,
          serType: this.SeachParams.serType,
          startTime: this.SeachParams.startTime,
          endTime: this.SeachParams.endTime,
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
        };
        _api.getAccountPayList(baseRequest).then(res => {
          if (res.code === 1) {
            
            this.seachDataList = res.data.ipage.records;
            this.page.total = res.data.ipage.total;
            this.page.pageNum = res.data.ipage.current;
            this.loading = false
            this.searchTabLoading = false;
            console.log(this.seachDataList, '22222222');
          }
        }).finally(() => {
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
      },
      toOrderDetails(row) {
        this.$router.push({ path: "/RecallOrder/Details", query: { type: 'edit', id: row.orderNo } })
      },
    },
  };
  </script>
  
  <style scoped lang="scss" ref="stylesheet/scss">
  .GlobalTable {
  
    // 备注说明
    .Remarks {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; //控制行数
      overflow: hidden;
    }
  
    .operation-btn {
      /deep/.el-button {
        margin-left: 0;
      }
    }
  
    .isdeal1 {
      border:1px solid #f59a23;
      display: inline-block;
      width: max-content;
      padding: 0px 4px;
      font-size: 14px;
      margin: 0 auto;
      border-radius: 5px;
      color: #f59a23;
      text-align: center;
      margin-left: 5px;
    }
  }
  </style>
  