<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="90"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <!-- <el-form-item class="el_from" label="回收商名称" slot="operationSelect" style="margin:0">
          <el-select v-model="merchantId" placeholder="请选择回收商" filterable clearable @change="handleChange">
            <el-option v-for="item in shanpsSelectList" :key="item.merchantId" :label="item.merchantName"
              :value="item.merchantId"></el-option>
          </el-select>
        </el-form-item> -->
      <el-form-item
        class="el_from"
        label="门店商名称"
        slot="operationSelect"
        style="margin: 0"
      >
        <el-select
          v-model="companyId"
          placeholder="请选择门店商"
          filterable
          clearable
          @change="handleChangeInquiry"
        >
          <el-option
            v-for="item in InquirySelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item class="el_from" label="交易门店" slot="operationSelect" style="margin:0">
          <el-select v-model="storeId" placeholder="请先选择交易门店" :disabled="isHasCompany" filterable clearable
            @change="handleChangeStore">
            <el-option v-for="item in storeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_from" label="原始订单号" slot="orderNo" style="margin:0">
          <el-input v-model="orderNo" clearable placeholder="请输入原始订单号查询"></el-input>
        </el-form-item> -->
      <el-form-item class="el_from" label="账单类型" slot="orderNo">
        <el-select
          v-model="opt"
          placeholder="请选择账单类型查询"
          filterable
          clearable
          @change="handleChangeOpt"
        >
          <el-option
            v-for="item in optList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item class="el_from" label="账单子类型" slot="serTypeList" style="margin:0">
          <el-select v-model="serType" placeholder="请选择账单子类型" filterable clearable @change="handleChangeSerType">
            <el-option v-for="item in serTypeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item> -->
      <div slot="AddSlot">
        <el-button size="small" type="danger" @click="handBtn()"
          >账户调账</el-button
        >
        <el-button type="warning" size="small" @click="outexport"
          >导入数据表格</el-button
        >
        <el-button
          :loading="isExport"
          size="small"
          type="success"
          icon="el-icon-download"
          @click="clickExcel"
          >导出账单</el-button
        >
      </div>
    </GlobalForm>
    <!-- 导入账单 -->
    <el-dialog
      class="add-dialog"
      title="导入账单"
      :visible.sync="importVisible"
      :close-on-click-modal="false"
      width="510px"
      @closed="exportinDialogClosed"
    >
      <div class="step">
        <div class="step-title">
          <span>说明：请按照下载模板表格，按商户填写虚拟账户账单</span>
        </div>
        <div class="step-content">
          <el-button
            type="success"
            icon="el-icon-download"
            size="medium"
            @click="download"
            >下载数据模板</el-button
          >
        </div>
        <el-form
          ref="importForm"
          :model="importForm"
          :rules="importRules"
          label-width="80px"
        >
          <el-form-item label="表格上传" prop="fileName" class="fileName">
            <el-input
              v-model="importForm.fileName"
              @clear="fileClear"
              :disabled="true"
              size="medium"
              clearable
            >
            </el-input>
            <div style="float: right; position: relative">
              <el-button size="medium" type="warning" icon="el-icon-upload2"
                >上传表格</el-button
              >
              <input
                accept=".xls, .xlsx"
                ref="fileUpload"
                @change="onUploadList"
                type="file"
                style="
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                "
              />
            </div>
            <div style="color: #ff687b">
              仅支持.xls及.xlsx格式,单次上传大小不超过5M
            </div>
          </el-form-item>
        </el-form>
        <div class="step2-content"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importVisible = false">取消</el-button>
        <el-button type="primary" :loading="isExport" @click.native="uploadFile">确定</el-button>
      </span>
    </el-dialog>
    <!-- 虚拟账户调账 -->
    <el-dialog
      class="add-dialog"
      title="虚拟账户调账"
      :visible.sync="xuniVisible"
      :close-on-click-modal="false"
      width="510px"
      @closed="xuniDialogClosed"
    >
      <el-form
        ref="form"
        :model="xunininitData"
        :rules="formRules"
        label-width="110px"
      >
        <el-form-item class="el_from" label="选择门店商家" prop="companyId">
          <el-select
            ref="select1"
            v-model="xunininitData.companyId"
            placeholder="请输入查询或选择门店商家"
            filterable
            clearable
          >
            <el-option
              v-for="item in storeoption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择账单类型" prop="opt" label-width="110px">
          <el-radio-group v-model="xunininitData.opt" @change="optchange">
            <el-radio label="01">上账</el-radio>
            <el-radio label="02">下账</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="xunininitData.opt == '01'"
          class="el_from"
          label="上账金额"
          prop="amountPrice"
        >
          <el-input
            placeholder="请输入上账金额"
            @input="limitInput($event, 'amountPrice')"
            v-model.trim="xunininitData.amountPrice"
          ></el-input>
          <span v-if="NumMoney" style="color: #f83232; font-weight: bold">{{
            NumMoney
          }}</span>
        </el-form-item>
        <el-form-item
          v-else
          class="el_from"
          label="下账金额"
          prop="xiaamountPrice"
        >
          <el-input
            placeholder="请输入下账金额"
            @input="limitInput($event, 'xiaamountPrice')"
            v-model.trim="xunininitData.xiaamountPrice"
          ></el-input>
          <span v-if="NumMoney" style="color: #f83232; font-weight: bold">{{
            NumMoney
          }}</span>
        </el-form-item>
        <el-form-item class="el_from" label="备注" prop="cause">
          <el-input
            maxlength="50"
            type="textarea"
            placeholder="请输入备注内容，最多50字"
            v-model="xunininitData.cause"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="xuniVisible = false">取消</el-button>
        <el-button type="primary" @click.native="onSubmit('form')"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 动态口令弹出框 -->
    <el-dialog
      :title="commandTitle"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="410px"
      @closed="commandClosed"
    >
      <div class="sure-tip">
        <span
          >请仔细核对{{
            xunininitData.opt == "01" ? "上账" : "下账"
          }}金额与收款金额是否一致</span
        >
      </div>
      <div class="amount-price">
        <span>{{ xunininitData.opt == "01" ? "上账" : "下账" }}金额：</span
        ><span>{{ fictitiousData.amountPrice }}</span>
      </div>
      <div class="command">
        <div style="width: max-content">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌验证码"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="submit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { formValidateMobile } from "@/utils/form-validate";
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      paramsExcel: {},
      storeoption: [],
      butLoading: false,
      xunininitData: {
        xiaamountPrice: "",
        amountPrice: "",
        companyId: "",
        opt: "01",
        cause: "",
      },
      commandTitle: "",
      commandShow: false,
      command: "",
      shanpsSelectList: [],
      staffList: [],
      params: {},
      fictitiousData: {},
      NumMoney: "",
      xuniformItemList: [
        { slotName: "companyId" },
        { slotName: "balance" },
        { slotName: "amountPrice" },
        {
          key: "voucher",
          type: "uploadGroup",
          labelName: "收款凭证",
          placeholder:
            "请上传本次的收款凭证（限3张内，大小不超过2M，格式为JPG或PNG）",
        },
        { slotName: "cause" },
      ],
      compnyList: {
        balance: 0,
        contactName: "",
        contactPhone: "",
      },
      formRules: {
        companyId: [
          {
            required: true,
            trigger: "change",
            message: "选择门店商家",
          },
        ],
        amountPrice: [
          {
            required: true,
            trigger: "blur",
            message: "输入上账金额",
          },
        ],
        xiaamountPrice: [
          {
            required: true,
            trigger: "blur",
            message: "输入下账金额",
          },
        ],
        opt: [
          {
            required: true,
            trigger: "change",
            message: "请选择账单类型",
          },
        ],
        cause: [
          {
            required: true,
            trigger: "blur",
            message: "请输入备注内容，最多50字",
          },
        ],
      },
      importForm: {
        fileName: "",
      },
      importRules: {
        fileName: [
          {
            required: true,
            message: "仅支持.xls及.xlsx格式,单次上传大小不超过5M",
            trigger: "blur",
          },
        ],
      },
      importVisible: false,
      xuniVisible: false,
      isExport: false,
      startTime: "",
      endTime: "",
      day60: 30 * 24 * 3600 * 1000,
      initData: {
        entryTime: [
          moment()
            .day(moment().day())
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      merchantId: "",
      companyId: "",
      storeId: "",
      orderNo: "",
      opt: "",
      InquirySelectList: [],
      storeList: [],
      serTypeList: [],
      serType: "",
      isHasCompany: true,
      formItemList: [
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "时间",
          clearable: true,
          lableWidth: "60px",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day60 ||
                  time.getTime() < this.pickerMinDate - this.day60
                );
              }
              return false;
            },
          },
        },
        { slotName: "operationSelect" },
        //   {
        //     key: "uniqNo",
        //     type: "input",
        //     labelName: "账单流水号",
        //     placeholder: "请输入账单流水号查询",
        //   },
        { slotName: "orderNo" },
        { slotName: "opt" },
        { slotName: "serTypeList" },
      ],
      optList: [
      {
          value: '01',
          label: '上账',
        },
        {
          value: '02',
          label: '下账',
        },
      
      ],
    };
  },
  created() {
    const params = {
      pageNum: 0,
      pageSize: 999,
    };
    this.orderNo = this.$route.query.orderNo || "";
    _api.listSimpleMerchantView(params).then((res) => {
      if (res.code === 1) {
        this.shanpsSelectList = res.data;
      }
    });
    _api.getrchantSelectList().then((res) => {
      if (res.code === 1) {
        this.InquirySelectList = res.data;
      }
    });
    _api
      .getSerTypeList({ accountType: "merchant", opt: this.opt })
      .then((res) => {
        if (res.code === 1) {
          this.serTypeList = res.data;
        }
      });
    this.handleConfirm(this.initData);
  },
  watch: {
    importForm(newVal) {
      console.log(newVal, "importForm");
      if (!this.importForm.fileName) {
        this.paramsExcel.file = "";
      }
    },
    companyId(newVal) {
      console.log(newVal);
      if (newVal != "") {
        this.isHasCompany = false;
      } else {
        this.storeId = "";
        this.isHasCompany = true;
      }
    },
    accountType(val) {
      if (val == "merchant") {
        this.orderNo = "";
        this.handleConfirm();
      }
      this.serType = "";
      this.opt = "";
      _api.getSerTypeList({ accountType: val, opt: this.opt }).then((res) => {
        if (res.code === 1) {
          this.serTypeList = res.data;
        }
      });
    },
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.xunininitData);
          this.fictitiousData = {
            amountPrice:
              this.xunininitData.opt == "01"
                ? this.xunininitData.amountPrice
                : this.xunininitData.xiaamountPrice,
            companyId: this.xunininitData.companyId,
            command: "",
            opt: this.xunininitData.opt,
            remark: this.xunininitData.cause,
          };
            this.commandTitle =
            this.xunininitData.opt == "01" ? "上账确认" : "下账确认";
          this.commandShow = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //导入数据表格
    outexport() {
      this.importVisible = true;
    },
    onUploadList(event, type) {
      console.log(event);
      this.paramsExcel.file = event.target.files;
      if (this.paramsExcel.file.length) {
        this.paramsExcel.excelName = event.target.files[0].name;
        this.importForm.fileName = event.target.files[0].name;
      }
    },
    // 确定导出
    uploadFile() {
      if (!this.paramsExcel.file || this.paramsExcel.file.length == 0) {
        return this.$message.error("请上传表格");
      }
      this.isExport=true
      const fd = new FormData();
      console.log(this.paramsExcel);
      fd.append("file", this.paramsExcel.file[0]); //文件
      console.log(fd);
      this.repeatData = [];
      _api
        .fictitiousOrderNo(fd)
        .then((res) => {
          console.log("_RequestUploads_", res);
          if (res.code === 1) {
            this.importForm.fileName = "";
            this.$refs.fileUpload.value = "";
            this.$message({
              type: "success",
              message: "导入成功！",
            });
            this.handleConfirm();
            this.importVisible = false;
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
          this.isExport=false
        })
        .catch((err) => {
          console.log(err);
          this.importForm.fileName = "";
          this.$refs.fileUpload.value = "";
          this.isExport=false
        });
    },
    //表格清理
    fileClear() {
      this.$refs.fileUpload.value = "";
      this.paramsExcel = {};
    },
    //
    xuniDialogClosed() {
      this.NumMoney = "";
      this.xunininitData = {
        xiaamountPrice: "",
        amountPrice: "",
        companyId: "",
        opt: "01",
        cause: "",
      };
      this.$refs["form"].resetFields();
    },
    // 弹窗关闭
    exportinDialogClosed() {
      if (this.$refs["addForm"]) {
        this.$refs["addForm"].resetFields();
      } else {
        this.$refs["importForm"].resetFields();
      }
      this.importForm = {
        fileName: "",
      };
      this.$refs.fileUpload.value = "";
      this.paramsExcel = {};
    },
    //下载模板
    download() {
      const link = document.createElement("a");
      link.href = "/static/导入差异基金虚拟账户账单模板.xlsx";
      link.setAttribute("download", "导入差异基金虚拟账户账单模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // 上账/下账 点击
    handBtn(type) {
      this.SelectList();
      this.xuniVisible = true;
    },
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      if (!Array.isArray(data.entryTime)) {
        (this.startTime = null), (this.endTime = null);
      } else {
        (this.startTime = moment(data.entryTime[0]).format("x")),
          (this.endTime = moment(data.entryTime[1]).format("x"));
      }
      let SeachParams = {};
      if (this.orderNo) {
        SeachParams = {
          orderNo: this.orderNo,
        };
      }
      if (data) {
        SeachParams = {
          merchantId: this.merchantId || "",
          companyId: this.companyId || "",
          storeId: this.storeId || "",
          uniqNo: data.uniqNo || "",
          orderNo: this.orderNo || data.orderNo || "",
          opt: this.opt || "",
          serType: this.serType || "",
          startTime: this.startTime,
          endTime: this.endTime,
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams. disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      console.log(SeachParams);
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    handleChangeSerType(val) {
      this.serType = val;
    },
    handleChangeOpt(val) {
      this.opt = val;
      this.serType = "";
      _api
        .getSerTypeList({ accountType: this.accountType, opt: this.opt })
        .then((res) => {
          if (res.code === 1) {
            this.serTypeList = res.data;
          }
        });
    },
    handleChangeInquiry(val) {
      console.log(val);
      this.companyId = val;
      if (val != "") {
        this.storeId = "";
        this.getStoreSelectList();
      }
    },
    handleChangeStore(val) {
      console.log(val);
      this.storeId = val;
    },
    // 获取门店列表
    getStoreSelectList() {
      _api.getStoreSelectList({ companyId: this.companyId }).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.storeList = res.data;
        }
      });
    },
    // 导出账单
    clickExcel() {
      let SeachParams = this.$store.state.tagsView.SeachParams;
      console.log(SeachParams);
      if (!SeachParams.startTime) {
        this.$message.error("请先选择账单时间（31天内）");
        return false;
      }
      if (
        (SeachParams.endTime / 1000 - SeachParams.startTime / 1000 + 1) /
          60 /
          60 /
          24 /
          31 >
        1
      ) {
        this.$message.error("选择的账单时间超过31天，请重新选择");
        return false;
      }
      this.isExport = true;
      SeachParams.accountType = this.accountType;
      _api
        .billExportExcel(SeachParams)
        .then((res) => {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "服务异常");
              }
            } catch (err) {
              const fileName =
                this.accountType == "merchant"
                  ? "回收商账单.xlsx"
                  : this.accountType == "company"
                  ? "门店商回收账单.xlsx"
                  : this.accountType == "companyActivity"
                  ? "门店商活动账单.xlsx"
                  : this.accountType == "companyDiffFundFictitious"
                  ? "门店差异基金虚拟账单.xlsx"
                  : "门店差异基金账单.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");

          this.isExport = false;
        })
        .catch((err) => {
          console.log("97899999");
          this.isExport = false;
        });
    },
    commandClosed() {
      this.commandShow = false;
      this.butLoading = false;
      this.command = "";
    },
    submit() {
      if (!this.command) {
        return this.$message.error("请输入动态口令");
      }
      this.fictitiousData.command = this.command;
      this.butLoading = true;
      _api
        .fictitiousupdownDown(this.fictitiousData)
        .then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.xuniVisible = false;
            this.commandShow = false;
            this.handleConfirm(this.initData);
          } else {
            this.$message({
              message: "添加失败",
              type: "error",
            });
          }
          this.butLoading = false;
        })
        .catch((err) => {
          this.butLoading = false;
        });
    },
    // 门店下拉
    SelectList() {
      _api.getSelectList().then((res) => {
        console.log(res);
        this.storeoption = res.data;
      });
    },
    optchange() {
      this.NumMoney = "";
      this.xunininitData.xiaamountPrice = "";
      this.xunininitData.amountPrice = "";
    },
    limitInput(value, name) {
      console.log(value);
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.initData[name] = "";
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      this.initData[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      this.NumMoney = valJoin.match(/^\d*(\.?\d{0,2})/g)[0]
        ? this.changeNumMoneyToChinese(valJoin.match(/^\d*(\.?\d{0,2})/g)[0])
        : "";
    },
    changeNumMoneyToChinese(money) {
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      ); //汉字的数字
      var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
      var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
      var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
      var cnInteger = "整"; //整数金额时后面跟的字符
      var cnIntLast = "元"; //整型完以后的单位
      var maxNum = 999999999999999.9999; //最大处理的数字
      var IntegerNum; //金额整数部分
      var DecimalNum; //金额小数部分
      var ChineseStr = ""; //输出的中文金额字符串
      var parts; //分离金额后用的数组，预定义
      var Symbol = ""; //正负值标记
      if (money == "") {
        return "";
      }

      money = parseFloat(money);
      if (money >= maxNum) {
        alert("超出最大处理数字");
        return "";
      }
      if (money == 0) {
        ChineseStr = cnNums[0] + cnIntLast + cnInteger;
        return ChineseStr;
      }
      if (money < 0) {
        money = -money;
        Symbol = "负 ";
      }
      money = money.toString(); //转换为字符串
      if (money.indexOf(".") == -1) {
        IntegerNum = money;
        DecimalNum = "";
      } else {
        parts = money.split(".");
        IntegerNum = parts[0];
        DecimalNum = parts[1].substr(0, 4);
      }
      if (parseInt(IntegerNum, 10) > 0) {
        //获取整型部分转换
        var zeroCount = 0;
        var IntLen = IntegerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = IntegerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              ChineseStr += cnNums[0];
            }
            zeroCount = 0; //归零
            ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            ChineseStr += cnIntUnits[q];
          }
        }
        ChineseStr += cnIntLast;
        //整型部分处理完毕
      }
      if (DecimalNum != "") {
        //小数部分
        var decLen = DecimalNum.length;
        for (let i = 0; i < decLen; i++) {
          let n = DecimalNum.substr(i, 1);
          if (n != "0") {
            ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (ChineseStr == "") {
        ChineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (DecimalNum == "") {
        ChineseStr += cnInteger;
      }
      ChineseStr = Symbol + ChineseStr;

      return ChineseStr;
    },
  },
};
</script>
  
  <style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
.step {
  .step-title {
    display: flex;
    color: #ff687b;
    margin-bottom: 18px;
  }

  .fileName {
    /deep/.el-input {
      width: 260px;
    }

    /deep/.el-button {
      // border-radius: 10px;
      margin-right: 0px;
    }
  }

  .step-content {
    margin-bottom: 20px;
  }
}
.amount-price {
  color: #f59a23;
  font-size: 14px;
  margin: 15px 0;
}

.sure-tip {
  color: #0981ff;
  font-size: 16px;
  padding-left: 10px;
  border-left: 3px solid;
}
.command {
  display: flex;
  align-items: center;

  /deep/.el-input {
    width: 300px;
  }
}
</style>
  